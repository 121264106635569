<template>
  <div class="container">
    <div class="legal-container">
      <h2 class="heading-secondary">FinTrivia Cash Contest Rules</h2>
      <h3 class="heading-tertiary">ELIGIBILITY</h3>
      <p class="legal">
       The FinTrivia Contest (“Contest”) is open to residents of Canada and the United States.






    </p>
    <p class="legal">
    If a minor, someone below the age of majority in their province or state, wins the contest, they must have a parent or guardian agree to the terms of the contest.
    </p>
    <p class="legal">
    No purchase is necessary to join the competition.
      </p>
    <p class="legal">Participants must have an INVRS account to qualify for the contest prizes.</p>
     <p class="legal">INVRS employees, contractors, advisors, directors, and their family members are ineligible for INVRS prizes.</p>
      <h3 class="heading-tertiary">CONTEST ENTRY</h3>
      <p class="legal">
        No purchase is necessary to join the contest.
      </p>
      <p class="legal">
      Creating an INVRS account automatically makes you <strong>eligible</strong> to compete in the contest.  Contest <strong>entry</strong> is automatic once you start answering FinTrivia questions during a contest period.
      
      </p>
      <p class="legal">
      Only one registration is allowed per person.  Multiple registrants are not eligible for prizes.
      </p>
      <h3 class="heading-tertiary">CONTEST STRUCTURE</h3>
      <p class="legal">
        The FinTrivia Contest will run on a monthly basis.

    </p>
      <p class="legal">
      The contest involves answering financial and investment-related trivia questions.


      </p>
      <p class="legal">
      The winning criteria will be announced on the last day of the contest and will vary between highest score, longest streak, and participant skill levels (beginner, intermediate, advanced).

      </p>
      <p class="legal">
      Prizes may be awarded under multiple criteria and may include multiple placements i.e. First, Second, Third.  This is to allow the possibility of there being more than one type of winner at the end of the month.
      </p>
       <h3 class="heading-tertiary">PRIZES</h3>
      <p class="legal">
       The monthly prize starts at $25 CAD/$17.50 USD based on 100 active participants and increases by $50 CAD for each additional 100 players. Maximum monthly prize is $1000 CAD.
      </p>
      <p class="legal">
      
      The prize is doubled for INVRS premium subscribers who have been subscribers for at least one month.  Maximum monthly premium prize is $2000 CAD.

      
      </p>
      <p class="legal">
      In the case where there are multiple winners under different criteria, the prize money is distributed between all winners.  The winnings amount received will vary depending on the number of winners and placements.

      </p>
      <h3 class="heading-tertiary">PRIZE DISTRIBUTION</h3>
      <p class="legal">
       All prizes are awarded via Interac e-Transfer for Canadians and PayPal for Americans and is sent to the email address registered with INVRS. 

    </p>
      <p class="legal">
        Winners will be announced within three days of month end on the INVRS website.
    </p>
      <p class="legal">
Winners are required to provide their name for the transfer.  In order to preserve their anonymity, winners can send their name via direct message on INVRS to @jeninvrs.
    </p>
      <p class="legal">
It is the participant’s responsibility to ensure their email address is correct.  Under no circumstances will prizes be sent to an email that is different to the one used to sign up to INVRS.
    </p>
      <p class="legal">
Prize money will be sent within two weeks after the contest closes.
    </p>
      <p class="legal">
Unclaimed awards will be forfeited by the end of the contest that is running when the announcement is made.  In other words, one month after the end of the contest in which the winner won.
        
      </p>
      <h3 class="heading-tertiary">TIE BREAKING RULES</h3>
      <p class="legal">
        
    In the event of a tie, the prize will be split equally among the tied participants.
      </p>
      <h3 class="heading-tertiary">DISQUALIFICATION</h3>
      <p class="legal">
       Participants found to be manipulating or abusing the contest, including using multiple accounts or unauthorized methods, will be disqualified.
      </p>
       <h3 class="heading-tertiary">RIGHT TO MODIFY OR CANCEL</h3>
      <p class="legal">
        INVRS reserves the right to modify, suspend, or cancel the contest at its sole discretion, including changing the prize structure or contest rules.
      </p>
      <h3 class="heading-tertiary">LEGAL COMPLIANCE</h3>
      <p class="legal">
        The FinTrivia Contest complies with the Competition Act, the Criminal Code, and the Privacy Act in Canada.


      </p>
      
       
      <h3 class="heading-tertiary">PERSONAL IDENTIFIABLE INFORMATION</h3>
      <p class="legal">
        Participants' personal information will only be used to manage the contest and will be handled in accordance with the INVRS Privacy Policy.
      </p>
      
       <h3 class="heading-tertiary">FAQ</h3>
      <p class="legal">
        <strong>Is there a minimum number of trivia questions I must answer to enter?</strong><br>
        No, there is no minimum or maximum number of questions.
      </p>
      <p class="legal">
        <strong>Can I participate if I’m not an INVRS premium subscriber?</strong><br>
        Yes, all INVRS account holders can participate. However, the prize is doubled for premium subscribers.
      </p>
      <p class="legal">
        <strong>What happens if I enter the contest after the start date?</strong><br>
        You can still participate, but only the answers submitted during the contest period will be counted.
      </p>
       <p class="legal">
        <strong>What happens if I find a mistake within a FinTrivia Question/Answer?</strong><br>
        If you find an error and it is confirmed to be an error by INVRS the question will be deleted and you will be awarded $200 USD <strong>Playdough</strong>.  This is manually factored into your FinTriva score on the last day of the contest.  Only the first person who identified the error as a comment to the question will be awarded the funds.
      </p>
    </div>
  </div>
</template>

<style scoped>


table {
  border-spacing: 0px;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  background-color: transparent; /* Change the background-color of table here */
  text-align: left; /* Change the text-alignment of table here */
}

th {
  font-weight: bold;
  border: 1px solid #cccccc; /* Change the border-color of heading here */
  padding: 8px;
}

td {
  border: 1px solid #cccccc; /* Change the border-color of cells here */
  padding: 8px;
}

.legal {
  margin-bottom: 3.2rem;
  line-height: 1.3;
}

.legal-container {
  padding: 14.4rem 12rem;
}

.heading-tertiary {
  margin-bottom: 1.6rem;
  line-height: 1.2;
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  .legal-container {
    padding: 14.4rem 6rem;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  .legal-container {
    padding: 12.8rem 2.4rem;
  }
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .legal-container {
    padding: 12.8rem 1.2rem;
  }
}
</style>
